<script setup>
import DetailsCard from "@/Components/Flexer/Card.vue";
import Pagination from "@/Components/Pagination/PaginationFE.vue";
import Filters from "@/Components/Table/Filters.vue";
import Table from "@/Components/Table/Table.vue";
import LocationLayout from "@/Layouts/LocationLayout.vue";

import { useForm } from "@inertiajs/vue3";
import { computed, inject } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const route = inject("route");
const props = defineProps({
  query: Object,
  flexer: Object,
  shifts: Object,
  positionOptions: Object,
  locationOptions: Object,
  liked: Boolean,
  hidden: Boolean
});
console.log(props.shifts, "props");
const typeFilters = {
  position: "Position",
  location: "Company",
  shifts: "Rating"
};

const form = useForm({
  orderBy: props.query.orderBy ?? "",
  orderDir: props.query.orderDir ?? "desc",

  position_id: props.query.position_id ?? "",
  minimum_rating: props.query.minimum_rating ?? "",
  location_id: props.query.location_id ?? "",
  page: props.query.page ?? 1
});

const getData = (resetPage = true) => {
  form.page = resetPage ? 1 : form.page;

  form.get(route("location.flexers.show", { id: props.flexer.id }), {
    preserveState: true,
    preserveScroll: true,
    only: ["shifts"]
  });
};

const tableSettings = [
  { label: t("Position"), width: "30%" },
  { label: t("Date"), orderBy: "start", width: "10%" },
  { label: t("Hours"), width: "10%" },
  { label: t("Company"), orderBy: "name", cell: "Bold", width: "30%" },
  { label: t("Rating"), orderBy: "rating", cell: "Rating" },
  { label: "", width: "2%" }
];

const tableData = computed(() =>
  props.shifts.data.map(shift => {
    const duration = Math.round((shift.duration / 3600) * 100) / 100;

    return {
      id: shift.id, // used as vue key if set
      // colorCode: shift.color_code, // used to set row color accent
      rowData: [
        shift.no_show ? shift.position + " (No show)" : shift.position,
        shift.start,
        duration,
        shift.employer_name,
        [shift.rating]
      ]
    };
  })
);

const setPage = e => {
  form.page = e;
  getData(false);
};
</script>

<template>
  <LocationLayout :title="$t(`Fl@xr`)">
    <template #sidebar>
      <DetailsCard :flexer="flexer" :liked="liked" :hidden="hidden"></DetailsCard>
    </template>
    <template #default>
      <div>
        <Filters
          :form="form"
          @anyUpdate="getData()"
          :data="flexer"
          :typeFilters="typeFilters"
          :positionOptions="positionOptions"
          :locationOptions="locationOptions"
        />
      </div>
      <div>
        <p class="mb-3 text-base font-bold text-blue">{{ $t('Statistics') }}</p>

        <Table
          class="mb-8"
          :tableSettings="tableSettings"
          :data="tableData"
          v-model:orderBy="form.orderBy"
          @update:orderBy="getData()"
          v-model:orderDir="form.orderDir"
          @update:orderDir="getData()"
        />
      </div>

      <Pagination
        class="mt-auto"
        v-if="typeof shifts !== 'undefined'"
        :links="shifts.links"
        @setPage="setPage"
        :pageData="shifts"
        :hideTotal="query.search != '' && query.search != null"
      />
    </template>
  </LocationLayout>
</template>
